.team {
    .member {
        .name {
            font-family: Opal;
            font-weight: 300;
            font-size: 1.4rem;
            margin-bottom: 0;
            line-height: 0.5;
        }
        .position {
            font-size: 0.8rem;
        }
    }
}
.values-list {
    .item {
        &:first-child {
            margin-top: 10px;
        }
        @media #{$medium-and-up} {
            &:nth-child(2) {
                margin-top: 10px;
            }
        }
        margin-bottom: 12px;
        font-size: 16px;
    }
}
// .cover-section {
//     .cover-container {
//         background-image: url('../images/about.jpg');
//     }
// }
