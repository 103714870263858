// Variables
@import 'variables';
// @import 'functions';
@import 'placeholders';
@import 'materialize';

.admin.content {
    margin-bottom: 0px;
    .menu-container {
        padding-left: 0;
        .menu.admin {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: #f1f1f1;
            list-style-type: none;
            li a {
                display: block;
                color: #000;
                padding: 8px 16px;
                text-decoration: none;
                &.active {
                    background-color: $primary-color;
                    color: white;
                }
                &:hover:not(.active) {
                    background-color: #555;
                    color: white;
                }
            }
        }
    }
}
.admin-title {
    h2 {
        font-size: 2rem;
        @media #{$large-and-up} {
            width: 80%;
            padding-left: 17%;
        }
    }
}
.btn-positive {
  color: #fff !important;
  letter-spacing: .5px;
  font-size: 1rem;
  padding: 0 1rem;
  background-color: $primary-color !important;
  i {
    font-size: 1.2rem;
  }
  &:hover {
    background-color: $secondary-color;
  }
}

.admin-content-box {
  .cover-container {
    >.row {
      margin-bottom: 0px!important;
    }
    .cover-input-container {
      padding-left: 0px;
    }
    .cover-validator-container {
      padding-top: 3%;
    }
    &.avatar {
      margin-bottom: 1rem;
    }
    .cover-input.content-inside {
      text-align: center;
      .cover-box {
        height: auto;
        width: 100%;
        margin: 15px auto;
        display: inline-block;
        &.circular {
          .image-container {
            width: 150px;
            height: 150px;
            border-radius: 75px;
            .change-image-cover {
              right: 2px;
              bottom: 20px;
            }
            .attachment-loader {
              border-radius: 50%;
              .loader-gif{
                top: 30%;
                left: 30%;
              }
            }
          }
        }
        &.square, &.circular {
          .image-container {
            margin: auto;
          }
          .cover-label {
            text-align: center;
          }
        }
        &.square {
          .image-container {
            width: 210px;
            height: 150px;
            border-radius: 10px;
          }
          .change-image-cover {
            right: -8px;
            bottom: -8px;
          }
        }
        &.rectangular {
          .image-container {
            margin: auto;
            width: 100%;
            height: 200px;
            border-radius: 10px;
            .change-image-cover {
              right: -8px;
              bottom: -8px;
            }
            .attachment-loader {
              border-radius: 6px;
              .loader-gif{
                top: 35%;
                left: 45%;
              }
            }
          }
        }
        .image-container {
          border: 2px dotted #a9a9a9;
          background-color: #ccc;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-size: cover;
          background-position: center center;
          position: relative;
          .image-form-input {
            display: none;
          }
          .change-image-cover {
            position: absolute;
            background-color: rgba(132, 132, 132, 0.8);
            border-radius: 50%;
            width: 25px;
            height: 25px;
            text-align: center;
            cursor: pointer;
            z-index: 10;
            border-width: 0px;
            i {
              font-size: 16px;
              color: #fff;
              padding-top: 4px;
              padding-left: 2px;
            }
          }
        }
      }
      .checkboxes-logo, .checkboxes-background {
        width: 100%;
        border: 1px solid #efefef;
        padding: 10px 15px;
        border-radius: 4px;
        &.tiny {
          margin: auto!important;
          width: 180px;
          margin: 0 20px;
          padding: 2px 10px;
          p label {
            font-size: 11px;
          }
        }
        .verify-type {
          font-family: opensans-semibold;
          font-size: 14px;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 0px;
          label {
            font-size: 13px;
          }
        }
        .correct-verification {
          input[type="radio"].with-gap:disabled:checked+label:before {
            border: 2px solid #009c30;
          }
          input[type="radio"]:disabled:checked+label:after {
            background-color: #009c30;
          }
        }
        .incorrect-verification {
          input[type="radio"].with-gap:disabled:checked+label:before {
            border: 2px solid #d40909;
          }
          input[type="radio"]:disabled:checked+label:after {
            background-color: #d40909;
          }
        }
      }
    }
    .cover-label {
      color: #7b7b7b;
      font-size: 12px;
      font-family: opensans-semibold;
      margin-top: 10px;
    }
  }
}
.attachment-loader {
  &.loader {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: black;
    position: relative;
    display: none;
    &.loading {
        display: block;
    }
    .loader-gif {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      position: absolute;
    }
  }
  .loader-gif {
    top: 32.5%;
    left: 32.5%;
    svg {
      path {
        fill: #fff;
      }
      rect {
        fill: #fff;
      }
    }
    &.loader-black {
      svg {
        path {
          fill: #000;
        }
        rect {
          fill: #000;
        }
      }
    }
  }
}
.checkboxes-logo, .checkboxes-background {
    width: 100%;
    border: 1px solid #efefef;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 0px auto 10px;
    .verify-type {
        font-family: opensans-semibold;
        font-size: 14px;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 0px;
        label {
            font-size: 13px;
        }
    }
    .correct-verification {
        input[type="radio"].with-gap:disabled:checked+label:before {
            border: 2px solid #009c30;
        }
        input[type="radio"]:disabled:checked+label:after {
            background-color: #009c30;
        }
    }
    .incorrect-verification {
        input[type="radio"].with-gap:disabled:checked+label:before {
            border: 2px solid #d40909;
        }
        input[type="radio"]:disabled:checked+label:after {
            background-color: #d40909;
        }
    }
}
.radio-answers, .checkboxes-answers, .reference-image-box {
  margin-top: 20px;
}
.input-field .helper-text.validate-text-errors{
    color:#F44336;
    font-weight: 600;
}
.full-height {
    height: 85vh;
}
