.card {
    .card-image {
        @extend %image-containers;
    }
    .avatar {
        width: 50px;
        height: 50px;
        float: right;
        margin-right: 20px;
        @extend %image-containers;
        &.speciality {
            width: 25px;
            height: 25px;
            float: left;
        }
    }
    .card-content {
        .title, .name {
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 1.2rem;
            &.about-us {
                margin-bottom: 14px;
            }
        }
        .name {
            font-size: 18px;
        }
        .date {
            color: #c6c6c6;
            font-size: 11px;
            margin-bottom: 5px;
        }
        .resume {
            font-size: 12px;
        }
    }
    .rating {
        height: 35px;
        padding: 0px 24px 16px;
        .stars {
            float: right;
            .checked {
                color: #e9c32c;
            }
        }
    }
    &.about-us {
        margin-top: 3rem;
        @media #{$medium-and-down} {
            display: block;
        }
        .title {
            margin-top: 0px;
        }
        .card-content p {
            font-size: 0.9rem;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
        .card-image {
            width: 40%;
            height: 330px;
            @media #{$medium-and-down} {
                width: 100%;
                height: 400px;
                min-width: 100%;
            }
            @media #{$small-and-down} {
                width: 100%;
                height: 280px;
                min-width: 100%;
            }
        }
    }
    &.testimonial {
        .card-content .resume {
            font-size: 12px;
            .avatar {

            }
        }
    }
    &.speciality {
        .card-image {
            .avatar.speciality {
                width: 30px;
                height: 30px;
                margin-top: 10px;
            }
        }
        .card-content {
            .resume {
                @media #{$medium-and-down} {
                    max-height: 125px;
                    min-height: 125px;
                }
                @media #{$large-and-up} {
                    max-height: 145px;
                    min-height: 145px;
                }
            }
        }
    }
}
.btn.contact-submit{
    width:120px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
}
#thank-you-modal {
    .read-more {
        width: unset;
    }
}
