.main-title {
    font-family: Opal;
    font-weight: 300;
    font-size: 2rem;
    line-height: 130%!important;
    margin-bottom: 0px;
}
.form-field-validation {
    display: none;
}
.carousel {
    &.loading {
        text-align: center;
        .preloader-wrapper {
            top: 45%;
        }
    }
    .controls {
        top: 38%;
        z-index: 1000;
        position: absolute;
        cursor: pointer;
        &.left {
            left: 2%;
        }
        &.right {
            right: 2%;
        }
        .material-icons {
            color:white;
            font-size: 75px;
        }
    }
}
.brand-logo img {
    height:60px;
    @media #{$medium-and-up} {
        height:70px;
    }
    @media #{$large-and-up} {
        padding-left: 30px;
    }
}
.page-header.brand-color {
    height:60px;
    line-height: 60px;
    @media #{$medium-and-up} {
        height:75px;
        line-height: 75px;
    }
}
.carousel {
    .carousel-item {
        width: 100%;
        height: 600px;
    }
    @media #{$small-and-down} {
        .carousel-item {
            height: 250px;
        }
    }
    @media #{$large-and-up} {
        height: 80vh !important;
    }
}
.container {
    @media #{$medium-and-down} {
        width: 95%!important;
    }
    @media #{$large-and-up} {
        width: 82.5%!important;
    }
}
.btn-flat.primary-btn {
    font-size: 11px;
    text-align: center;
    border: solid 1px $primary-color;
    color: $primary-color!important;
    &:hover {
        color: white!important;
        background-color: $primary-color!important;
    }
    &.read-more{
        width: 150px;
    }
}
.nav-mobile, .sidenav {
    li a {
        font-family: Opal;
        font-size: 1.2rem;
    }
}
.page-footer {
    .container {
        @media #{$medium-and-down} {
            .col {
                &.brand, &.social  {
                    text-align: center;
                }
            }
        }
        .brand-logo img {
            height: 110px;
            @media #{$medium-and-down} {
                height: 60px;
            }
        }
        .information {
            .title {
                font-size: 1rem;
                font-weight: 800;
            }
            &.social {
                text-align: center;
                .contact-link {
                    color: white;
                    span {
                        margin: 0.1em 0.7em;
                        font-size: 22px;
                        @media #{$medium-and-down} {
                            font-size: 18px;
                        }
                    }
                }
            }
            ul {
                .address {
                    height: 60px;
                    p {
                        margin: 5px 0;
                        &:first-child {
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    .footer-copyright {
        .container {
            p, a {
                font-size: 11px;
            }
            a {
                margin: 1em 0.6em;
            }
        }
    }
}
.cover-section {
    .col {
        padding: 0px;
    }
    .cover-container {
        height: 440px;
        @extend %image-containers;
    }
}
ul.list {
    margin-left: 8%;
    margin-right: 5%;
    li{
        padding-bottom: 5px;
    }
    &.decimal-bullets li{
        list-style-type:decimal;
    }
    &.circle-bullets li{
        list-style-type:circle;
    }
}
.comments-send {
    color: #9dcb66;
    font-size: 10em;
    padding-top: 15px;
}
