// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,700,800');

$fa-font-path: "../webfonts";

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@font-face {
    font-family: 'Opal';
    src: url('fonts/Opal.eot');
    src: url('fonts/Opal.eot') format('embedded-opentype'),
         url('fonts/Opal.woff2') format('woff2'),
         url('fonts/Opal.woff') format('woff'),
         url('fonts/Opal.ttf') format('truetype'),
         url('fonts/Opal.svg#Opal') format('svg');
}

@import 'sass/variables';
@import 'sass/placeholders';
// MaterializeCSS
@import 'sass/materialize';
@import 'sass/main';
@import 'sass/home';
@import 'sass/about';
